<template>
  <div>
    <div
      class="
        glass-overlay
        flex
        items-center
        justify-center
        font-bold
        text-lg
        absolute
        top-0
        bottom-0
        right-0
        left-0
        bg-black
        z-50
      "
    >
      <h2>No tickets.</h2>
    </div>
    <div
      class="
        cursor-pointer
        py-4
        p-6
        text-black
        bg-white
        hover:bg-gray-50
        border border-gray-150 border-l-0 border-r-0
      "
    >
      <span style="color: #d4b376" class="font-bold mr-2 text-xs">
        #125412
      </span>
      <span class="uppercase text-gray-400 text-xs"> Lorem, ipsum. </span>
      <div class="py-1 flex items-center">
        <h1 class="text-md font-semibold text-black capitalize">
          Lorem ipsum dolor sit.
        </h1>
        <button
          class="
            ml-2
            bg-green-50
            text-green-400
            font-semibold
            px-3
            py-0
            rounded
            capitalize
          "
        >
          CLOSED
        </button>
      </div>
      <div class="py-1 text-xs">
        {{ $moment().format("DD MMMM YYYY") }}
      </div>
    </div>
    <div
      class="
        cursor-pointer
        py-4
        p-6
        text-black
        bg-white
        hover:bg-gray-50
        border border-gray-150 border-l-0 border-r-0
      "
    >
      <span style="color: #d4b376" class="font-bold mr-2 text-xs">
        #125412
      </span>
      <span class="uppercase text-gray-400 text-xs"> Lorem, ipsum. </span>
      <div class="py-1 flex items-center">
        <h1 class="text-md font-semibold text-black capitalize">
          Lorem ipsum dolor sit.
        </h1>
        <button
          class="
            ml-2
            bg-green-50
            text-green-400
            font-semibold
            px-3
            py-0
            rounded
            capitalize
          "
        >
          CLOSED
        </button>
      </div>
      <div class="py-1 text-xs">
        {{ $moment().format("DD MMMM YYYY") }}
      </div>
    </div>
    <div
      class="
        cursor-pointer
        py-4
        p-6
        text-black
        bg-white
        hover:bg-gray-50
        border border-gray-150 border-l-0 border-r-0
      "
    >
      <span style="color: #d4b376" class="font-bold mr-2 text-xs">
        #125412
      </span>
      <span class="uppercase text-gray-400 text-xs"> Lorem, ipsum. </span>
      <div class="py-1 flex items-center">
        <h1 class="text-md font-semibold text-black capitalize">
          Lorem ipsum dolor sit.
        </h1>
        <button
          class="
            ml-2
            bg-red-50
            text-red-400
            font-semibold
            px-3
            py-0
            rounded
            capitalize
          "
        >
          CLOSED
        </button>
      </div>
      <div class="py-1 text-xs">
        {{ $moment().format("DD MMMM YYYY") }}
      </div>
    </div>
    <div
      class="
        cursor-pointer
        py-4
        p-6
        text-black
        bg-white
        hover:bg-gray-50
        border border-gray-150 border-l-0 border-r-0
      "
    >
      <span style="color: #d4b376" class="font-bold mr-2 text-xs">
        #125412
      </span>
      <span class="uppercase text-gray-400 text-xs"> Lorem, ipsum. </span>
      <div class="py-1 flex items-center">
        <h1 class="text-md font-semibold text-black capitalize">
          Lorem ipsum dolor sit.
        </h1>
        <button
          class="
            ml-2
            bg-green-50
            text-green-400
            font-semibold
            px-3
            py-0
            rounded
            capitalize
          "
        >
          CLOSED
        </button>
      </div>
      <div class="py-1 text-xs">
        {{ $moment().format("DD MMMM YYYY") }}
      </div>
    </div>
    <div
      class="
        cursor-pointer
        py-4
        p-6
        text-black
        bg-white
        hover:bg-gray-50
        border border-gray-150 border-l-0 border-r-0
      "
    >
      <span style="color: #d4b376" class="font-bold mr-2 text-xs">
        #125412
      </span>
      <span class="uppercase text-gray-400 text-xs"> Lorem, ipsum. </span>
      <div class="py-1 flex items-center">
        <h1 class="text-md font-semibold text-black capitalize">
          Lorem ipsum dolor sit.
        </h1>
        <button
          class="
            ml-2
            bg-green-50
            text-green-400
            font-semibold
            px-3
            py-0
            rounded
            capitalize
          "
        >
          CLOSED
        </button>
      </div>
      <div class="py-1 text-xs">
        {{ $moment().format("DD MMMM YYYY") }}
      </div>
    </div>
    <div
      class="
        cursor-pointer
        py-4
        p-6
        text-black
        bg-white
        hover:bg-gray-50
        border border-gray-150 border-l-0 border-r-0
      "
    >
      <span style="color: #d4b376" class="font-bold mr-2 text-xs">
        #125412
      </span>
      <span class="uppercase text-gray-400 text-xs"> Lorem, ipsum. </span>
      <div class="py-1 flex items-center">
        <h1 class="text-md font-semibold text-black capitalize">
          Lorem ipsum dolor sit.
        </h1>
        <button
          class="
            ml-2
            bg-red-50
            text-red-400
            font-semibold
            px-3
            py-0
            rounded
            capitalize
          "
        >
          CLOSED
        </button>
      </div>
      <div
        class="
          cursor-pointer
          py-4
          p-6
          text-black
          bg-white
          hover:bg-gray-50
          border border-gray-150 border-l-0 border-r-0
        "
      >
        <span style="color: #d4b376" class="font-bold mr-2 text-xs">
          #125412
        </span>
        <span class="uppercase text-gray-400 text-xs"> Lorem, ipsum. </span>
        <div class="py-1 flex items-center">
          <h1 class="text-md font-semibold text-black capitalize">
            Lorem ipsum dolor sit.
          </h1>
          <button
            class="
              ml-2
              bg-green-50
              text-green-400
              font-semibold
              px-3
              py-0
              rounded
              capitalize
            "
          >
            CLOSED
          </button>
        </div>
        <div class="py-1 text-xs">
          {{ $moment().format("DD MMMM YYYY") }}
        </div>
      </div>
      <div
        class="
          cursor-pointer
          py-4
          p-6
          text-black
          bg-white
          hover:bg-gray-50
          border border-gray-150 border-l-0 border-r-0
        "
      >
        <span style="color: #d4b376" class="font-bold mr-2 text-xs">
          #125412
        </span>
        <span class="uppercase text-gray-400 text-xs"> Lorem, ipsum. </span>
        <div class="py-1 flex items-center">
          <h1 class="text-md font-semibold text-black capitalize">
            Lorem ipsum dolor sit.
          </h1>
          <button
            class="
              ml-2
              bg-green-50
              text-green-400
              font-semibold
              px-3
              py-0
              rounded
              capitalize
            "
          >
            CLOSED
          </button>
        </div>
        <div class="py-1 text-xs">
          {{ $moment().format("DD MMMM YYYY") }}
        </div>
      </div>
      <div
        class="
          cursor-pointer
          py-4
          p-6
          text-black
          bg-white
          hover:bg-gray-50
          border border-gray-150 border-l-0 border-r-0
        "
      >
        <span style="color: #d4b376" class="font-bold mr-2 text-xs">
          #125412
        </span>
        <span class="uppercase text-gray-400 text-xs"> Lorem, ipsum. </span>
        <div class="py-1 flex items-center">
          <h1 class="text-md font-semibold text-black capitalize">
            Lorem ipsum dolor sit.
          </h1>
          <button
            class="
              ml-2
              bg-red-50
              text-red-400
              font-semibold
              px-3
              py-0
              rounded
              capitalize
            "
          >
            CLOSED
          </button>
        </div>
        <div class="py-1 text-xs">
          {{ $moment().format("DD MMMM YYYY") }}
        </div>
      </div>
      <div class="py-1 text-xs">
        {{ $moment().format("DD MMMM YYYY") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style scoped>
.chat-message:last-child {
  margin-top: 1rem;
}
.glass-overlay {
  background: rgba(246, 240, 228, 0.494);
  backdrop-filter: blur(10.3px);
  -webkit-backdrop-filter: blur(10.3px);
  border: 1px solid rgba(255, 252, 252, 0.44);
}
</style>